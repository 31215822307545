import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import logo from "../../../assets/images/onboarding/access-logo.png";
import passport_img from "../../../assets/images/onboarding/passport.png";
import id_img from "../../../assets/images/onboarding/id.png";
import dl_img from "../../../assets/images/onboarding/dl.png";
import upload_icon from "../../../assets/images/onboarding/upload.png";
import { useMagic } from "../../../hooks/MagicProvider";
import "./kyc.css";
import { getKycURL } from "../../../api";
import { getKycVerification } from "../../../api/kyc";

const socket = io(process.env.REACT_APP_SERVER); // Replace with your server URL in production

const KYC = ({ onNext, isMobile, containerHeight }) => {
  const { userMetadata } = useMagic();
  const [verificationStatus, setVerificationStatus] = useState("Not Started");
  const [kycData, setKycData] = useState();
  const { step } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (userMetadata) {
        const data = await getKycURL({ address: userMetadata.publicAddress });
        setKycData(data);
      }
    };
    fetchData();
  }, [userMetadata]);

  useEffect(() => {
    const fetchVerification = async () => {
      if (userMetadata && kycData?.verification_id) {
        const data = await getKycVerification({
          address: userMetadata.publicAddress,
          verification_id: kycData.verification_id,
        });
        if (data.verified && step === "kyc") onNext();
      }
    };
    fetchVerification();

    if (kycData && kycData.status) {
      console.log("Updated KYC data:", kycData);
      setVerificationStatus("In Progress");
    }

    // Listen for KYC updates
    socket.on("kycUpdate", (data) => {
      console.log(data);

      setVerificationStatus(data.verified ? "Verified" : "Failed");
    });

    // Clean up the event listener
    return () => {
      socket.off("kycUpdate");
    };
  }, [kycData, userMetadata]);

  const renderStatusMessage = () => {
    switch (verificationStatus) {
      case "Not Started":
        return "Please start the KYC process.";
      case "In Progress":
        return "Your KYC verification is in progress. Please complete the process in the form below.";
      case "Verified":
        return "Your KYC verification is complete!";
      case "Failed":
        return "KYC verification failed. Please try again or contact support.";
      default:
        return "";
    }
  };

  return (
    <div className="kyc w-100">
      <div className="row">
        <div className="col-2 text-start">
          <img className="section-logo" src={logo} alt="logo" />
        </div>
        <div className="col-8 section-title-block">
          {!isMobile && (
            <>
              <h2 className="section-title">Prove you are you</h2>
              <p>Complete the KYC process for verification</p>
            </>
          )}
        </div>
        <div className="col-2 text-end">
          <i className="fa fa-info-circle"></i>
        </div>
      </div>
      {isMobile && (
        <div className="section-title-block">
          <h2 className="section-title">Prove you are you</h2>
          <p>Complete the KYC process for verification</p>
        </div>
      )}

      {/* <div className="kyc-status-message">
        <p>{renderStatusMessage()}</p>
      </div> */}

      {kycData && kycData.form_url && (
        <iframe
          src={kycData.form_url}
          title="AML Bot KYC"
          width="100%"
          height={containerHeight - 250}
          style={{ border: "none" }}
        ></iframe>
      )}

      {isMobile ? (
        <a className="skip-link">Why KYC is important?</a>
      ) : (
        <button className="btn btn-primary w-100 importnat-btn">
          Why KYC is important?
        </button>
      )}
    </div>
  );
};

export default KYC;
