import "./home.css";
import HeroSection from "../../sections/Home/hero";
import VisionSection from "../../sections/Home/vision";
import InfoSection from "../../sections/Home/info";
import UtilitySection from "../../sections/Home/utility";
import EcoSection from "../../sections/Home/ecosystem";
import JoinSection from "../../sections/Home/join";

import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import RoadmapSection from "../../sections/Home/roadmap";
import { Navbar } from "../../components/navbar";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const Home = () => {
  const canvasRef = useRef(null);
  const images = useRef([]);
  const screens = useRef({ frame: 0 });

  const frameCount = 1011 + 60 + 240;
  const currentFrame = (index) =>
    `./assets/images/hero/${(index + 1).toString().padStart(4, "0")}.png`;

  useEffect(() => {
    const parentElement = document.getElementById("home");
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const setCanvasSize = () => {
      canvas.width = parentElement.clientWidth;
      canvas.height = parentElement.clientWidth / 1.777 - 65;
      /* } */
    };

    setCanvasSize();

    for (let i = 0; i < frameCount; i++) {
      const img = new Image();
      if (i > 524 && i < 584) img.src = currentFrame(523);
      else if (i >= 584 && i <= 960) img.src = currentFrame(i - 60);
      else if (i > 960 && i < 1200) img.src = currentFrame(900);
      else if (i >= 1200) img.src = currentFrame(i - 60 - 240);
      else img.src = currentFrame(i);
      images.current.push(img);
    }

    let canvasTimeline = gsap.timeline();

    canvasTimeline.to(screens.current, {
      frame: frameCount - 1,
      snap: "frame",
      ease: "none",
      scrollTrigger: {
        scrub: 4,
      },
      delay: 1,
      duration: 100000,
      onUpdate: () => {
        if (screens.current.frame > 610 && screens.current.frame < 720) {
          canvas.width = parentElement.clientWidth / 2.5;
          canvas.height = (parentElement.clientWidth / 1.777 - 65) / 2.5;
        } else setCanvasSize();
        render();
      },
    });

    images.current[0].onload = render;

    function render() {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(
        images.current[screens.current.frame],
        0,
        0,
        canvas.width,
        canvas.height
      );
    }

    const handleResize = () => {
      setCanvasSize();
      render();
    };

    window.addEventListener("resize", handleResize);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#home",
        start: "top top",
        end: "bottom bottom",
        scrub: 4, // Adjust this value for smoothness
        toggleActions: "play reverse play reverse",
      },
    });

    const hero = document.getElementById("hero");
    const vision = document.getElementById("vision");
    const info = document.getElementById("info");
    const utility = document.getElementById("utility");
    const ecosystem = document.getElementById("ecosystem");
    const roadmap = document.getElementById("roadmap");
    const join = document.getElementById("join");

    const heroHeight = hero.clientHeight;
    const visionHeight = vision.clientHeight;
    //    const infoHeight = info.clientHeight;
    //    const utilityHeight = utility.clientHeight;
    //    const ecosystemHeight = ecosystem.clientHeight;
    const roadmapHeight = roadmap.clientHeight;
    //    const joinHeight = join.clientHeight;

    // Hero
    //gsap.fromTo(hero, { y: 400, opacity: 0 }, { y: 0, opacity: 1 });
    gsap.fromTo(canvasRef, { scale: 0 }, { scale: 1 });
    gsap.set(hero, { visibility: "visible" });
    tl.fromTo(hero, { y: 0 }, { y: 0 }, "+=7.5");
    tl.fromTo(hero, { y: 0 }, { y: -heroHeight, duration: 1.5 });
    tl.set(hero, { visibility: "hidden" });

    // Vision
    tl.set(vision, { visibility: "visible" }, "+=1.5");
    tl.fromTo(".vision-1", { y: 100, opacity: 0 }, { y: 0, opacity: 1 });
    tl.fromTo(".vision-2", { opacity: 0 }, { opacity: 1 });
    tl.fromTo(".vision-3", { opacity: 0 }, { opacity: 1 });
    tl.fromTo(".vision-4", { opacity: 0 }, { opacity: 1 });
    tl.to(vision, { y: 0 }, "+=3.5");
    tl.fromTo(vision, { y: 0 }, { y: -visionHeight, duration: 1 });
    tl.set(vision, { visibility: "hidden" });

    // Info
    tl.set(info, { visibility: "visible" }, "+=2")
      .fromTo(info, { opacity: 0 }, { opacity: 1 })
      .to(info, { y: 0 }, "+=1.5");
    tl.fromTo(info, { opacity: 1 }, { opacity: 0 });
    tl.set(info, { visibility: "hidden" });

    // Utility
    tl.set(utility, { visibility: "visible" });
    tl.fromTo(
      ".utility-box",
      { scaleX: 0, translate: "-50%" },
      { scaleX: 1, translate: 0 }
    );
    tl.fromTo(
      ".benefit-box",
      { scaleX: 0, translate: "-50%" },
      { scaleX: 1, translate: 0 },
      "-=0.6"
    );
    tl.fromTo(".utility-1", { opacity: 0 }, { opacity: 1 });
    tl.fromTo(".utility-2", { y: 100, opacity: 0 }, { y: 0, opacity: 1 });
    tl.to(utility, { x: 0 }, "+=1");
    tl.to(".utility-2", { opacity: 0 });
    tl.to(".utility-1", { opacity: 0 });
    tl.to(".utility-box", { scaleX: 0, translate: "-50%" });
    tl.to(".benefit-box", { scaleX: 0, translate: "-50%" }, "-=0.4");
    tl.set(utility, { visibility: "hidden" });

    // Ecosystem
    tl.set(ecosystem, { visibility: "visible" });
    tl.fromTo(
      ".ecosystem-container",
      { scaleY: 0, translateY: "-100%" },
      { scaleY: 1, translateY: 0, duration: 1, ease: "power2.out" }
    );
    tl.fromTo(
      ".ecosystem-container .container",
      { opacity: 0 },
      { opacity: 1, ease: "power2.out" }
    );

    tl.fromTo(
      ".ecosystem-title",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1 },
      "<"
    );
    tl.fromTo(".ecosystem-fade", { opacity: 0 }, { opacity: 1 }, "<");
    tl.fromTo("#ecosystem .btn", { scale: 0 }, { scale: 1 }, "<");
    tl.fromTo(
      "#ecosystem .fade-1",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1 }
    );
    tl.fromTo(
      "#ecosystem .fade-2",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1 }
    );
    tl.fromTo(
      "#ecosystem .fade-3",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1 }
    );
    tl.fromTo(
      "#ecosystem .fade-4",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1 }
    );

    tl.to(ecosystem, { y: 0 }, "+=1");

    tl.to(".ecosystem-container", {
      scaleY: 0,
      translateY: "-100%",
      ease: "power2.out",
    });
    tl.to(".ecosystem-container .container", { opacity: 0 });
    tl.to(".ecosystem-title", { y: 0, opacity: 1 }, "<");
    tl.to(".ecosystem-fade", { opacity: 0 }, "<");
    tl.to("#ecosystem .btn", { scale: 0 }, "<");
    tl.to("#ecosystem .fade-4", { y: -100, opacity: 0 });
    tl.to("#ecosystem .fade-3", { y: -100, opacity: 0 });
    tl.to("#ecosystem .fade-2", { y: -100, opacity: 0 });
    tl.to("#ecosystem .fade-1", { y: -100, opacity: 0 });
    tl.to(ecosystem, { opacity: 0 });
    tl.set(ecosystem, { visibility: "hidden" });

    // Roadmap
    tl.set(roadmap, { visibility: "visible" });
    tl.fromTo("#roadmap .roadmap-1", { y: 100 }, { y: 0 });

    tl.fromTo(".timeline-1 .timeline-badge", { scale: 0 }, { scale: 1 });
    tl.fromTo(".timeline", { opacity: 0 }, { opacity: 1 });
    tl.fromTo(".timeline-1 .date", { scaleX: 0 }, { scaleX: 1 });
    tl.fromTo(
      ".timeline-1 .timeline-panel",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, ease: "power2.out" }
    );
    tl.fromTo(".timeline-2 .timeline-badge", { scale: 0 }, { scale: 1 });
    tl.fromTo(".timeline-2 .date", { scaleX: 0 }, { scaleX: 1 });
    tl.fromTo(
      ".timeline-2 .timeline-panel",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, ease: "power2.out" }
    );
    tl.fromTo(".timeline-3 .timeline-badge", { scale: 0 }, { scale: 1 });
    tl.fromTo(".timeline-3 .date", { scaleX: 0 }, { scaleX: 1 });
    tl.fromTo(
      ".timeline-3 .timeline-panel",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, ease: "power2.out" }
    );

    tl.to(roadmap, { y: 0 }, "+=1");

    tl.fromTo(roadmap, { y: 0 }, { y: -roadmapHeight });
    tl.set(roadmap, { visibility: "hidden" });

    // Join
    tl.set("#canvas_main", {
      bottom: 0,
      top: "auto",
      transform: "translate(-50%, 0)",
    });
    tl.set(join, { visibility: "visible" });
    tl.to(join, { y: 0 }, "+=5");
    tl.fromTo(join, { opacity: 0 }, { opacity: 1 });

    return () => {
      window.removeEventListener("resize", handleResize);
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, [frameCount]);

  return (
    <>
      <Navbar />
      <div className="content">
        <div id="home">
          <div className="bg-fix" />
          <HeroSection />
          <VisionSection />
          <InfoSection />
          <UtilitySection />
          <EcoSection />
          <RoadmapSection />
          <JoinSection />

          <canvas id="canvas_main" ref={canvasRef} />
        </div>
      </div>
    </>
  );
};

export default Home;
