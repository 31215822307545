import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./onboarding.css";
import "react-step-progress-bar/styles.css";
import ProgressB from "../../components/ProgressBar";
import Access from "../../sections/Onboarding/Access";
import KYC from "../../sections/Onboarding/KYC";
import Funds from "../../sections/Onboarding/Funds";
import RXG from "../../sections/Onboarding/RXG";

const Onboarding = () => {
  const [direction, setDirection] = useState("forward");
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);
  const sectionRef = useRef(null);
  const navigate = useNavigate();
  const { step } = useParams();

  const sections = [
    { name: "access", component: Access },
    { name: "kyc", component: KYC },
    { name: "funds", component: Funds },
    { name: "rxg", component: RXG },
  ];

  // Get the current step index based on the URL parameter
  const currentStep = sections.findIndex((s) => s.name === step) || 0;

  const nextStep = async () => {
    if (currentStep < sections.length - 1) {
      setDirection("forward");
      const nextSection = sections[currentStep + 1];
      navigate(`/onboarding/${nextSection.name}`);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setDirection("backward");
      navigate(`/onboarding/${sections[currentStep - 1].name}`);
    }
  };

  useEffect(() => {
    const updateContainerSize = () => {
      if (containerRef.current) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const paddingOffset = viewportHeight * 0.04;

        if (isMobile) {
          const newWidth = containerRef.current.offsetWidth * 0.96;
          const newHeight = viewportHeight - containerTop;
          setContainerSize({ width: newWidth, height: newHeight });
        } else {
          const newHeight = viewportHeight - containerTop - paddingOffset;
          setContainerSize({ width: viewportWidth, height: newHeight });
        }
      }
    };

    const checkIfMobile = () => {
      const newIsMobile =
        window.innerWidth <= 768 || window.innerWidth < window.innerHeight;
      setIsMobile(newIsMobile);
    };

    checkIfMobile();
    updateContainerSize();

    window.addEventListener("resize", checkIfMobile);
    window.addEventListener("resize", updateContainerSize);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
      window.removeEventListener("resize", updateContainerSize);
    };
  }, [isMobile]);

  return (
    <div id="onboarding" className="onboarding">
      <div className="container-fluid">
        <div className="progressBarContainer">
          <ProgressB currentStep={currentStep} totalSteps={sections.length} />
        </div>
        <div
          className={`onboarding-container ${isMobile ? "mobile" : ""}`}
          ref={containerRef}
          style={{ height: `${containerSize.height}px` }}
        >
          {sections.map((section, index) => {
            const SectionComponent = section.component;
            return (
              <div
                key={section.name}
                ref={sectionRef}
                className={`section-wrapper ${
                  index === currentStep ? "active" : ""
                } ${
                  direction === "forward" ? "slide-forward" : "slide-backward"
                }`}
              >
                <SectionComponent
                  onNext={nextStep}
                  onPrev={prevStep}
                  isActive={index === currentStep}
                  containerWidth={containerSize.width}
                  containerHeight={containerSize.height - 10}
                  isMobile={isMobile}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
