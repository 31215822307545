import React from "react";

const SVGElement = ({
  x1,
  y1,
  x2,
  y2,
  cx,
  cy,
  label,
  percentage,
  color,
  textPosition,
  first,
}) => {
  return (
    <g className="distribution-group">
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={color}
        strokeWidth="0.5"
        className={first}
      />
      <line
        x1={x2}
        y1={y2}
        x2={cx}
        y2={cy}
        stroke={color}
        strokeWidth="0.5"
        className={first}
      />
      <circle cx={cx} cy={cy} r="4" fill={color} className={first} />
      <circle
        cx={cx}
        cy={cy}
        r="6"
        fill={color}
        opacity="0.5"
        className={first}
      />
      <circle
        cx={cx}
        cy={cy}
        r="9"
        fill={color}
        opacity="0.2"
        className={first}
      />
      <circle
        cx={cx}
        cy={cy}
        r="12"
        fill={color}
        opacity="0.1"
        className={first}
      />
      <text x={x1 + textPosition} y={y1 + 5} fill={color} className={first}>
        <tspan>{percentage}</tspan> &nbsp;
        {first && <br />}
        {label}
      </text>
    </g>
  );
};

export default SVGElement;
