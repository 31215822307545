import { Link } from "react-router-dom";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
import logo from "../assets/images/logo2.png";

export const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg profile-navbar">
      <div className="container">
        <Link className="navbar-brand" to="/#home">
          <img src={logo} alt="rexla-logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse flex-row-reverse"
          id="navbarNav"
        >
          <ul className="navbar-nav gap-4 align-items-center">
            <li className="nav-item">
              <Link className="nav-link" to="/hub" target="_blank">
                Products
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/ico">
                RXG
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#career">
                Careers
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://rexla.com/rwf/"
                target="_blank"
              >
                RWF
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://rexla.com/news/"
                target="_blank"
              >
                Innovation
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://rexla.com/contact/"
                target="_blank"
              >
                Contacts
              </a>
            </li>
            <li className="nav-item">
              {/*  <a className="btn btn-primary" href="#">
                GET STARTED
              </a> */}
              {/* <ConnectButton /> */}
              <button className="btn btn-primary wallet-btn">Help</button>
              <button className="btn btn-primary wallet-btn ms-2">
                <i className="far fa-comment" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
