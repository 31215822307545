import "./info.css";

const InfoSection = () => {
  return (
    <div className="section" id="info">
      <div>
        <div className="main-circle" id="info_main">
          32,5%
          <h5>Token Sale</h5>
        </div>

        <div className="info-circle circle-1">
          8%
          <p>Liquidity</p>
        </div>
        <div className="info-circle circle-2">
          27%
          <p>Reward Pool</p>
        </div>
        <div className="info-circle circle-3">
          3%
          <p>Partners & Advisors</p>
        </div>
        <div className="info-circle circle-4">
          10%
          <p>Team</p>
        </div>
        <div className="info-circle circle-5">
          12%
          <p>Foundation</p>
        </div>
        <div className="info-circle circle-6">
          7,5%
          <p>Marketing</p>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
