import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";

const FrameCanvas = ({ frames, width, height }) => {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const images = useRef([]);
  const frameCount = frames.length;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    contextRef.current = context;

    // Load all images
    frames.forEach((src, index) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        images.current[index] = img;
        if (index === 0) drawImage(0); // Draw the first frame
      };
    });

    const drawImage = (index) => {
      if (images.current[index]) {
        context.clearRect(0, 0, width, height);
        context.drawImage(images.current[index], 0, 0, width, height);
      }
    };

    // GSAP Animation
    const tl = gsap.timeline({
      repeat: 1, // Loop the animation
    });

    tl.to(
      { frame: 0 },
      {
        frame: frameCount - 1,
        snap: "frame",
        ease: "none",
        duration: frameCount / 30, // Assuming 30fps
        onUpdate: function () {
          drawImage(this.targets()[0].frame);
        },
      }
    );

    return () => {
      tl.kill();
    };
  }, [frames, width, height, frameCount]);

  return <canvas ref={canvasRef} width={width} height={height}></canvas>;
};

export default FrameCanvas;
