// import discount_ico from "../../../assets/images/discount.png";
// import reward_ico from "../../../assets/images/reward.png";
// import gover_ico from "../../../assets/images/governance.png";
import "./utility.css";

const UtilitySection = () => {
  return (
    <div className="section text-start d-flex align-items-center" id="utility">
      <div className="utility-container d-flex">
        <div className="utility-box position-relative">
          <div>
            <div className="utility-1">
              <h5>TOKEN</h5>
              <h1>Utility</h1>
              <p>
                RXG is a fixed supply utility token that incentivises active
                users, early adopters and investors. We encourage you to take a
                deeper dive into our release schedule, projections and rewards
                structure outlined in our Tokenomics Paper below.
              </p>

              <p>
                If you would like to learn more about how RXG is integrated into
                our suite of services, please read our Whitepaper:
              </p>
            </div>
            <div className="utility-2">
              <button className="btn btn-primary mt-4">REXLA ECOSYSTEM</button>
            </div>
          </div>
        </div>
        <div className="benefit-box position-relative">
          <div>
            <div className="utility-1">
              <h5>TOKEN</h5>
              <h1>Benefits</h1>
              <h6>RXG Token: The Catalyst for Change</h6>
              <p>
                RXG is the cornerstone of our ecosystem, facilitating seamless
                transactions, powering innovative solutions, and unlocking new
                possibilities for our community. As a utility token, RXG plays a
                pivotal role in enabling various functions within our platform:
                trading, investment, governance, and more.
              </p>
            </div>
            <div className="utility-2 mt-4">
              <h6>Our Mission</h6>
              <p>
                Our mission at RXG is clear: to empower individuals with the
                tools and resources they need to thrive in the digital age.
                Through our comprehensive suite of products and services, we
                strive to make digital finance accessible, secure, and
                user-friendly for all. RXG is the thread that ties them all
                together. One method of value exchange to power a diverse set of
                initiatives suited for the decentralised world.
              </p>
              {/* <div className="d-flex align-items-center gap-4 mb-3 mt-4">
                <img src={discount_ico} alt="discount" />
                <div>
                  <h6>Discounts</h6>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 mb-3">
                <img src={reward_ico} alt="reward" />
                <div>
                  <h6>Staking Rewards</h6>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4">
                <img src={gover_ico} alt="governance" />
                <div>
                  <h6>Governance Participation</h6>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UtilitySection;
