import wallet from "../../../assets/images/ecosystem/wallet.png";
import project from "../../../assets/images/ecosystem/lock.png";
import exchange from "../../../assets/images/ecosystem/exchange.png";
import guild from "../../../assets/images/ecosystem/guild.png";
import "./ecosystem.css";

const EcoSection = () => {
  return (
    <div className="section d-flex flex-column" id="ecosystem">
      <div className="ecosystem-container text-white d-flex">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-start">
              <h1 className="utility-title">Utility Token</h1>
              <p className="utility-subtitle">
                The native cryptocurrency of the Rexla ecosystem, providing
                <br />
                discounts, staking rewards, and governance rights.
              </p>
            </div>
            <div className="col-lg-6 text-start d-flex align-items-center gap-5">
              <h1 className="mint-title">10</h1>
              <div className="ml-3 w-100">
                <h3 className="Jeko-light ms-4">
                  Billion RXG tokens will be minted
                </h3>
                <button className="btn btn-outline-primary mt-2">
                  No new tokens created in the future
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="eco-sub-block container flex-fill d-flex align-items-center flex-column justify-content-center">
        <div className="ecosystem-title-block position-relative text-center">
          <h5 className="ecosystem-fade">REXLA</h5>
          <h1 className="ecosystem-title">Ecosystem</h1>
          <p className="ecosystem-subtitle ecosystem-fade">
            RXG Token unlocks the Rexla Ecosystem
          </p>
        </div>
        <div className="row">
          <div className="col-md-3 text-center fade-1">
            <img src={wallet} alt="wallet" />
            <h4 className="text-white">REXLA WALLET</h4>
            <h3>Secure Digital Wallet</h3>
            <p>
              A state-of-the-art wallet for managing and transacting
              cryptocurrencies, featuring multi-factor authentication and robust
              encryption.
            </p>
          </div>
          <div className="col-md-3 text-center fade-2">
            <img src={project} alt="project" />
            <h4>REXLA PROTECT</h4>
            <h3>Blockchain Assurance</h3>
            <p>
              Ensures secure access to digital assets in cases of incapacitation
              or death, with designated guardians and legal executors.
            </p>
          </div>
          <div className="col-md-3 text-center fade-3">
            <img src={exchange} alt="exchange" />
            <h4>REXLA EXCHANGE</h4>
            <h3>Trading Platform</h3>
            <p>
              A user-friendly platform for buying, selling, and trading digital
              assets, supported by liquidity pools and advanced trading tools.
            </p>
          </div>
          <div className="col-md-3 text-center fade-4">
            <img src={guild} alt="guild" />
            <h4>REXLA GUILD</h4>
            <h3>Educational Hub</h3>
            <p>
              Offers online courses, workshops, and seminars on blockchain
              technology, aiming to educate and empower individuals in the
              digital economy.
            </p>
          </div>
        </div>
        <div className="text-center w-100 mt-5">
          <button className="btn btn-primary btn-lg">GET RXG TOKEN</button>
        </div>
      </div>
    </div>
  );
};

export default EcoSection;
