import React, { useState } from "react";
import "./form.css"; // Custom CSS file for styling

const AccountComp = () => {
  const [formData, setFormData] = useState({
    fullName: "Tony Stark",
    email: "tony@starkindustries.com",
    phone: "+39 392 76 59 683",
    birth: "29 May 1991",
    address: "Via San Cristofaro 63",
    city: "Naples",
    state: "Italy",
  });

  const [phoneValid, setPhoneValid] = useState(false); // For validation example
  const [showPhoneError, setShowPhoneError] = useState(false);

  const handlePhoneChange = (e) => {
    const phoneValue = e.target.value;
    setFormData({ ...formData, phone: phoneValue });

    // Basic validation example
    const isValidPhone = /^\+?\d{10,15}$/.test(phoneValue);
    setPhoneValid(isValidPhone);
    setShowPhoneError(!isValidPhone);
  };

  return (
    <form className="custom-form">
      {/* Full Name */}
      <div className="form-group">
        <label className="form-label">Full Name</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={formData.fullName}
            onChange={(e) =>
              setFormData({ ...formData, fullName: e.target.value })
            }
          />
          <div className="input-group-append">
            <i className="fa fa-check" />
          </div>
        </div>
      </div>

      {/* Email */}
      <div className="form-group">
        <label className="form-label">E-Mail</label>
        <div className="input-group">
          <input
            type="email"
            className="form-control"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          <div className="input-group-append valid">
            <i className="fa fa-check" />
          </div>
        </div>
      </div>

      {/* Phone */}
      <div className="form-group">
        <label className="form-label">Phone</label>
        <div className="input-group">
          <input
            type="tel"
            className={`form-control ${!phoneValid ? "invalid" : ""}`}
            value={formData.phone}
            onChange={handlePhoneChange}
          />
          <div
            className={`input-group-append ${phoneValid ? "valid" : "invalid"}`}
          >
            {phoneValid ? (
              <i className="fa fa-check" />
            ) : (
              <i className="fa fa-minus" />
            )}
          </div>
        </div>
        {showPhoneError && (
          <small className="form-text text-danger">
            Enter a valid phone number
          </small>
        )}
      </div>

      {/* Birth Date */}
      <div className="form-group">
        <label className="form-label">Birth</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={formData.birth}
            onChange={(e) =>
              setFormData({ ...formData, birth: e.target.value })
            }
          />
          <div className="input-group-append">
            <i className="fa fa-check" />
          </div>
        </div>
      </div>

      {/* Address */}
      <div className="form-group">
        <label className="form-label">Address</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={formData.address}
            onChange={(e) =>
              setFormData({ ...formData, address: e.target.value })
            }
          />
          <div className="input-group-append">
            <i className="fa fa-check" />
          </div>
        </div>
      </div>

      {/* City & State */}
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label">City</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={formData.city}
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
              />
              <div className="input-group-append">
                <i className="fa fa-check" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <label className="form-label">State</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={formData.state}
                onChange={(e) =>
                  setFormData({ ...formData, state: e.target.value })
                }
              />
              <div className="input-group-append">
                <i className="fa fa-check" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccountComp;
