import {
  simulateContract,
  waitForTransactionReceipt,
  writeContract,
} from "@wagmi/core";
import { config } from "../config";
import { abi } from "./abi";
import { parseEther } from "viem";
import showSwal from "../utils/showSwal";
import { ethers } from "ethers";

export const buyTokens = async (price) => {
  try {
    const { request } = await simulateContract(config, {
      abi,
      address: "0xa8979EA7092b4a55D6D8897Bc1fBd65Cc0f66aC1",
      functionName: "buyTokens",
      args: [],
      value: parseEther(price.toString()),
    });

    const hash = await writeContract(config, request);
    await waitForTransactionReceipt(config, {
      hash,
    });

    const tokens = price / process.env.REACT_APP_PRICE;
    showSwal(`${tokens} RXG tokens are minted!`, "success");
    return { sucess: true, hash };
  } catch (err) {
    console.log(err);
    /* if (err.code !== 4001)
      showSwal(err?.error?.message || err.message, "warning"); */
    return;
  }
};

export const buyTokensMagic = async (price, provider) => {
  try {
    if (!provider) {
      throw new Error("Magic provider instance not found");
    }

    const signer = provider.getSigner();
    const contractAddress = "0xa8979EA7092b4a55D6D8897Bc1fBd65Cc0f66aC1";
    const contract = new ethers.Contract(contractAddress, abi, signer);

    const priceInWei = ethers.utils.parseEther(price.toString());

    /* const estimatedGas = await contract.estimateGas.buyTokens({
      value: priceInWei,
    });

    // Add some buffer to the gas limit
    const gasLimit = estimatedGas.mul(120).div(100); // 20% buffer */

    const tx = await contract.buyTokens({ value: priceInWei /* gasLimit */ });
    const receipt = await tx.wait();

    const tokens = price / process.env.REACT_APP_PRICE;
    showSwal(`${tokens} RXG tokens are minted!`, "success");
    return { success: true, hash: receipt.transactionHash };
  } catch (err) {
    console.error("Error buying tokens:", err);
    /* showSwal(err.message || "An error occurred while buying tokens", "warning"); */
    return { success: false, error: err.message };
  }
};
