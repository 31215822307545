import React, { useState } from "react";
import "./form.css"; // Using the same CSS file for styling consistency

const loginHistory = [
  { date: "Thu 24 September 2024", ip: "IP 191.11.23.24.21", device: "iPhone" },
  {
    date: "Thu 24 September 2024",
    ip: "IP 591.11.35.214.21",
    device: "Mac Studio",
  },
  {
    date: "Thu 24 September 2024",
    ip: "IP 131.12.55.44.21",
    device: "Unknown",
  },
];

const SecurityComp = () => {
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [phone, setPhone] = useState("+39 392 76 59 683");

  const toggle2FA = () => {
    setTwoFactorEnabled(!twoFactorEnabled);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const deleteLoginHistoryItem = (index) => {
    // Implement your deletion logic here, for now, we just log the item.
    console.log(`Deleting login history item at index ${index}`);
  };

  return (
    <div className="security-section custom-form">
      {/* 2 Factor Authentication */}
      <h5 className="text-start mt-4 position-relative">
        2 Factor Authentication&nbsp;
        <span className={`text-${twoFactorEnabled ? "primary" : "danger"}`}>
          {twoFactorEnabled ? "Enabled" : "Disabled"}
        </span>
      </h5>
      <div className="form-group ">
        <label className="form-label">Phone</label>
        <div className="input-group mt-3">
          <input
            type="tel"
            className="form-control"
            value={phone}
            onChange={handlePhoneChange}
          />
          <div
            className={`input-group-append toggle-btn ${
              twoFactorEnabled ? "valid" : "invalid"
            }`}
            onClick={toggle2FA}
          >
            {twoFactorEnabled ? (
              <i className="fa fa-check" />
            ) : (
              <i className="fa fa-minus" />
            )}
          </div>
        </div>
      </div>

      {/* Login History */}
      <h5 className="mt-4 text-start">Login History</h5>
      {loginHistory.map((item, index) => (
        <div className="input-group" key={index}>
          <div key={index} className="history-item form-control">
            <div className="row align-items-center">
              <div className="col-md-4">
                <p>{item.date}</p>
              </div>
              <div className="col-md-4 text-start">
                <p>{item.ip}</p>
              </div>
              <div className="col-md-4 text-start">
                <p>{item.device}</p>
              </div>
            </div>
          </div>
          <div
            className="input-group-append invalid"
            onClick={() => deleteLoginHistoryItem(index)}
          >
            <i className="fa fa-minus"></i>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SecurityComp;
