import logo from "../../../assets/images/onboarding/logo-white.png";
import FrameCanvas from "../../../components/FrameCanvas";
import "./rxg.css";
import { useNavigate } from "react-router-dom";

const RXG = ({ containerHeight }) => {
  const navigate = useNavigate();
  const frames = Array.from(
    { length: 91 },
    (_, i) => `./assets/images/coin/${(i + 1).toString().padStart(4, "0")}.png`
  );
  const purchaseRXG = () => {
    navigate("/ico");
  };

  return (
    <div className="rxg w-100">
      <div className="rxg-left flex-grow-1">
        <div className="text-start w-100">
          <img className="section-logo" src={logo} alt="logo" />
        </div>
        <div className="d-flex align-items-center jusitfy-content-center h-100">
          <FrameCanvas
            frames={frames}
            width={containerHeight / 2}
            height={containerHeight / 2}
          />
        </div>
        <button
          className="btn btn-primary bg-white w-100"
          onClick={() => {
            purchaseRXG();
          }}
        >
          Purchase RXG
        </button>
      </div>
      <div className="rxg-right d-flex align-items-center justify-content-center flex-grow-1">
        <a>
          Explore Hub <i className="fa fa-arrow-right" />
        </a>
      </div>
    </div>
  );
};

export default RXG;
