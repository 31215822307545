import wallet from "../../../assets/images/ecosystem/wallet.png";
import project from "../../../assets/images/ecosystem/lock.png";
import exchange from "../../../assets/images/ecosystem/exchange.png";
import guild from "../../../assets/images/ecosystem/guild.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ecosystem.css";

const EcoSection = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "30px",
  };

  return (
    <div className="section d-flex flex-column" id="ecosystem">
      <div className="ecosystem-container text-white">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <div className="text-start flex-grow-1">
              <h1 className="utility-title">Utility Token</h1>
              <p className="utility-subtitle">
                The native cryptocurrency of the Rexla ecosystem, providing
                discounts, staking rewards, and governance rights.
              </p>
            </div>
            <div className="text-center">
              <h1>10</h1>
              <div>
                <h4 className="mt-0">Billion RXG tokens will be minted</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ecosystem-notify">
        <h4>NO NEW TOKENS CREATED IN THE FUTURE</h4>
      </div>
      <div className="eco-sub-block">
        <div className="ecosystem-title-block position-relative">
          <h1 className="ecosystem-title-xx">REXLA</h1>
          <h1 className="ecosystem-title-x">Ecosystem</h1>
          <p className="ecosystem-title-sub">
            RXG Token unlocks the Rexla Ecosystem
          </p>
        </div>
        <div className="section-carousel">
          <Slider {...settings}>
            <div className="eco-section">
              <img src={wallet} alt="wallet" />
              <h4 className="text-white">REXLA WALLET</h4>
              <h3>Secure Digital Wallet</h3>
              <p>
                A state-of-the-art wallet for managing and transacting
                cryptocurrencies, featuring multi-factor authentication and
                robust encryption.
              </p>
            </div>
            <div className="eco-section">
              <img src={project} alt="project" />
              <h4>REXLA PROTECT</h4>
              <h3>Blockchain Assurance</h3>
              <p>
                Ensures secure access to digital assets in cases of
                incapacitation or death, with designated guardians and legal
                executors.
              </p>
            </div>
            <div className="eco-section">
              <img src={exchange} alt="exchange" />
              <h4>REXLA EXCHANGE</h4>
              <h3>Trading Platform</h3>
              <p>
                A user-friendly platform for buying, selling, and trading
                digital assets, supported by liquidity pools and advanced
                trading tools.
              </p>
            </div>
            <div className="eco-section">
              <img src={guild} alt="guild" />
              <h4>REXLA GUILD</h4>
              <h3>Educational Hub</h3>
              <p>
                Offers online courses, workshops, and seminars on blockchain
                technology, aiming to educate and empower individuals in the
                digital economy.
              </p>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default EcoSection;
