import { Link } from "react-router-dom";
import logo from "../assets/images/logo2.png";
import WalletButton from "../components/WalletConnectButton";

export const LandingNavbar = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/#home">
          <img src={logo} alt="rexla-logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse flex-row" id="navbarNav">
          <ul className="navbar-nav gap-4 align-items-center">
            <li className="nav-item">
              <Link className="nav-link" to="/hub" target="_blank">
                Products
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/ico">
                RXG
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://rexla.com/careers/"
                target="_blank"
              >
                Careers
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://rexla.com/rwf/"
                target="_blank"
              >
                RWF
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://rexla.com/news/"
                target="_blank"
              >
                Innovations
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://rexla.com/contact/"
                target="_blank"
              >
                Contacts
              </a>
            </li>
          </ul>
        </div>
        <button className="btn btn-light">Join now</button>
      </div>
    </nav>
  );
};
