import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

window.addEventListener("beforeunload", () => {
  sessionStorage.setItem("scrollPosition", window.scrollY);
});

window.addEventListener(
  "load",
  () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition && parseInt(scrollPosition) > 0) {
      gsap.to(window, { duration: 1, scrollTo: { y: 0 } });
      sessionStorage.removeItem("scrollPosition");
    }
  },
  false
);
