import SVGElement from "../../../components/SVGElement";
import "./info.css";

const dataPoints = [
  {
    x1: 385,
    y1: 335,
    x2: 445,
    y2: 335,
    cx: 550,
    cy: 455,
    label: "Token Sale",
    percentage: "32.5%",
    color: "#6fbaf0",
    textPosition: -185,
  },
  {
    x1: 1105,
    y1: 135,
    x2: 1050,
    y2: 135,
    cx: 942,
    cy: 240,
    label: "Team",
    percentage: "10%",
    color: "#fff",
    textPosition: 10,
  },
  {
    x1: 1400,
    y1: 195,
    x2: 1345,
    y2: 195,
    cx: 1240,
    cy: 300,
    label: "Reward Pool",
    percentage: "27%",
    color: "#fff",
    textPosition: 10,
  },
  {
    x1: 1450,
    y1: 545,
    x2: 1395,
    y2: 545,
    cx: 1285,
    cy: 645,
    label: "Partners & Advisors",
    percentage: "3%",
    color: "#fff",
    textPosition: 10,
  },
  {
    x1: 1405,
    y1: 810,
    x2: 1350,
    y2: 810,
    cx: 1245,
    cy: 705,
    label: "Marketing",
    percentage: "7.5%",
    color: "#fff",
    textPosition: 10,
  },
  {
    x1: 1260,
    y1: 895,
    x2: 1205,
    y2: 895,
    cx: 1100,
    cy: 795,
    label: "Liquidity",
    percentage: "8%",
    color: "#fff",
    textPosition: 10,
  },
  {
    x1: 715,
    y1: 870,
    x2: 770,
    y2: 870,
    cx: 875,
    cy: 760,
    label: "Foundation",
    percentage: "12%",
    color: "#fff",
    textPosition: -175,
  },
];

const InfoSection = () => {
  /* useEffect(() => {
    // GSAP animations
    gsap.from(".pie-section", {
      duration: 1.5,
      opacity: 0,
      stagger: 0.3,
      ease: "power3.inOut",
    });
  }, []); */

  return (
    <div className="section" id="info">
      <div className="container-fluid">
        <svg width="100%" height="100%" viewBox="0 0 1920 1029">
          {dataPoints.map((data, index) => (
            <SVGElement
              key={index}
              x1={data.x1}
              y1={data.y1}
              x2={data.x2}
              y2={data.y2}
              cx={data.cx}
              cy={data.cy}
              label={data.label}
              percentage={data.percentage}
              color={data.color}
              textPosition={data.textPosition}
              first={index === 0 ? "info-first" : ""}
            />
          ))}
        </svg>
      </div>
    </div>
  );
};

export default InfoSection;
