import "./vision.css";

const VisionSection = () => {
  return (
    <div className="section" id="vision">
      <div className="container-fluid">
        <h1 className="vision-1 token-title mt-4">
          <span>RXG</span> <br /> TOKEN
        </h1>
        <div className="text-center px-1 vision-bottom">
          <div className="vision-2">
            <h5>OUR VISION</h5>
            <p>
              At the core of RXG lies a bold vision to democratise access to
              financial services, emboldening the individual to seize new
              opportunities and contribute to a better future.
            </p>
          </div>
          <div className="vision-4">
            <button className="btn btn-outline-primary mt-2">Learn More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionSection;
