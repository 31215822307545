import React, { useState } from "react";
import "./settings.css"; // Custom CSS for styling
import ReactFlagsSelect from "react-flags-select";

const SettingsComp = () => {
  const [emailNotification, setEmailNotification] = useState(true);
  const [smsNotification, setSMSNotification] = useState(false);
  const [pushNotification, setPushNotification] = useState(true);
  const [selected, setSelected] = useState("");

  const handleToggle = (setToggle) => {
    setToggle((prevState) => !prevState);
  };

  return (
    <div className="settings">
      <h5 className="text-center">Manage your settings</h5>

      {/* Toggle Switches */}
      <div className="form-group">
        <label className="switch-label">E-mail notifications</label>
        <div className="switch">
          <input
            type="checkbox"
            checked={emailNotification}
            onChange={() => handleToggle(setEmailNotification)}
          />
          <span className="slider round"></span>
        </div>
      </div>

      <div className="form-group">
        <label className="switch-label">SMS notifications</label>
        <div className="switch">
          <input
            type="checkbox"
            checked={smsNotification}
            onChange={() => handleToggle(setSMSNotification)}
          />
          <span className="slider round"></span>
        </div>
      </div>

      <div className="form-group">
        <label className="switch-label">Push notifications</label>
        <div className="switch">
          <input
            type="checkbox"
            checked={pushNotification}
            onChange={() => handleToggle(setPushNotification)}
          />
          <span className="slider round"></span>
        </div>
      </div>

      {/* Export Data Button */}
      <div className="country-selector text-center">
        <ReactFlagsSelect
          selected={selected}
          onSelect={(code) => setSelected(code)}
        />
      </div>
    </div>
  );
};

export default SettingsComp;
