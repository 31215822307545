import { useEffect } from "react";
import Slider from "react-slick";
import "./everyone.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
gsap.registerPlugin(ScrollTrigger);

const items = [
  { title: "Me", src: "./assets/images/everyone/everyone-1.jpg" },
  { title: "Fiancé", src: "./assets/images/everyone/everyone-2.jpg" },
  { title: "Dad", src: "./assets/images/everyone/everyone-3.jpg" },
  { title: "Mom", src: "./assets/images/everyone/everyone-4.jpg" },
  { title: "Grandpa", src: "./assets/images/everyone/everyone-5.jpg" },
  { title: "Grandma", src: "./assets/images/everyone/everyone-6.jpg" },
];
const Everyone = () => {
  const settings = {
    dots: true,
    arrows: false,
    className: "center",
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // Split the heading text into individual words
    const headingText = new SplitType(".everyone h1", {
      types: "words, chars",
    });

    // Create a timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".everyone",
        start: "top 60%",
        toggleActions: "play none none reverse",
      },
    });

    // Add animations to the timeline
    tl.fromTo(
      headingText.words,
      { y: 50, opacity: 0, scale: 0.95 },
      {
        y: 0,
        opacity: 1,
        scale: 1,
        duration: 1.2,
        ease: "power3.out",
        stagger: 0.2,
      }
    )
      .fromTo(
        ".everyone .section-detail",
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
        },
        "-=0.5" // Start 0.5 seconds before the previous animation ends
      )
      .fromTo(
        ".everyone .btn-primary",
        { opacity: 0, y: 20, scale: 0.9 },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 1.2,
          ease: "power3.out",
        },
        "-=0.5" // Start 0.5 seconds before the previous animation ends
      )
      .fromTo(
        ".everyone .slick-slide > div",
        { scale: 0.8, opacity: 0 },
        {
          scale: 1,
          opacity: 1,
          duration: 1.5,
          ease: "power3.out",
          stagger: 0.3,
        },
        "-=1" // Start 1 second before the previous animation ends
      );

    // Clean up function
    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div className="everyone">
      <div className="container-fluid">
        <h1 className="text-dark section-title">
          MADE FOR <span>EVERYONE</span>
        </h1>
        <div className="container">
          <p className="text-dark section-detail mt-3">
            Don’t fear the future, create it with Rexla. - it’s for everyone.
            <br />
            Learn, earn, trade and protect your digital assets all in one place.
          </p>
          <button className="btn btn-primary mt-5">Waiting List</button>
        </div>
      </div>
      <div className="slider-container">
        <Slider {...settings}>
          {items.map((item, key) => (
            <div key={key}>
              <img src={item.src} alt={item.title} />
              <p>{item.title}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Everyone;
