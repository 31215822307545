import React, { useState } from "react";
import "./privacy.css"; // Custom CSS for styling

const PrivacyComp = () => {
  const [showPersonalInfo, setShowPersonalInfo] = useState(true);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(true);

  const handleToggle = (setToggle) => {
    setToggle((prevState) => !prevState);
    console.log("asdfadfadadsfadfaafd");
  };

  return (
    <div className="privacy">
      <h5 className="text-center">Manage what others can see about you</h5>

      {/* Toggle Switches */}
      <div className="form-group">
        <label className="switch-label">Show personal informations</label>
        <div className="switch">
          <input
            type="checkbox"
            checked={showPersonalInfo}
            onChange={() => handleToggle(setShowPersonalInfo)}
          />
          <span className="slider round"></span>
        </div>
      </div>

      <div className="form-group">
        <label className="switch-label">Show e-mail</label>
        <div className="switch">
          <input
            type="checkbox"
            checked={showEmail}
            onChange={() => handleToggle(setShowEmail)}
          />
          <span className="slider round"></span>
        </div>
      </div>

      <div className="form-group">
        <label className="switch-label">Show phone number</label>
        <div className="switch">
          <input
            type="checkbox"
            checked={showPhoneNumber}
            onChange={() => handleToggle(setShowPhoneNumber)}
          />
          <span className="slider round"></span>
        </div>
      </div>

      {/* Export Data Button */}
      <div className="export-data text-center">
        <button className="btn btn-export">
          <i className="fa fa-download" /> Export datas
        </button>
      </div>

      {/* Delete Account Link */}
      <div className="delete-account text-center mt-3">
        <a href="#" className="delete-link">
          Delete account
        </a>
      </div>
    </div>
  );
};

export default PrivacyComp;
