import "./access.css";
import FrameCanvas from "../../../components/FrameCanvas";
import logo from "../../../assets/images/onboarding/access-logo.png";
import qr from "../../../assets/images/onboarding/qr.png";
import CircularCarousel from "../../../components/CircularCarousel";
import WalletButton from "../../../components/WalletConnectButton";
import { useMagic } from "../../../hooks/MagicProvider";
import { useEffect } from "react";
import { userSignup } from "../../../api";

const Access = ({ onNext, isMobile, containerHeight, containerWidth }) => {
  const { isLoggedIn, userMetadata } = useMagic();

  useEffect(() => {
    if (userMetadata) {
      userSignup(userMetadata);
    }
  }, [userMetadata]);

  const frames = Array.from(
    { length: 100 },
    (_, i) =>
      `../assets/images/onboarding/${(i + 1).toString().padStart(4, "0")}.png`
  );

  const fundWallet = () => {
    onNext();
  };

  return (
    <div
      className="access"
      style={{ height: isMobile ? "auto" : containerHeight }}
    >
      <div className="access-left">
        {!isMobile && (
          <div className="logo-block text-start">
            <img className="section-logo" src={logo} alt="logo" />
            <i className="fa fa-info-circle float-end" />
          </div>
        )}

        <div className="d-flex flex-column gap-3 flex-fill justify-content-center">
          {/* <button className="btn btn-primary">Create Account</button> */}
          {/* <button className="btn btn-outline-primary">Connect Wallet</button> */}
          <WalletButton
            className="btn btn-outline-primary"
            text={"Create Wallet"}
          />
          <button
            className="btn btn-outline-primary"
            disabled={!userMetadata}
            onClick={() => fundWallet()}
          >
            Fund Wallet
          </button>
          {!isMobile && (
            <>
              <div className="w-full name-form">
                <p className="float-start mb-0">Referred by anyone?</p>
                <input
                  className="w-100"
                  type="text"
                  placeholder="Enter their username"
                />
              </div>
              <div className="d-flex align-items-center gap-4 qr-block">
                <img src={qr} alt="qr-code" />
                <div className="text-start">
                  <h3>Quick Access</h3>
                  <p>Scan the QR code with your phone to get access</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="access-right">
        {!!isMobile && (
          <div className="logo-block text-start position-absolute w-100 py-4 px-5">
            <img className="section-logo" src={logo} alt="logo" />
            <i className="fa fa-info-circle float-end" />
          </div>
        )}
        <FrameCanvas
          frames={frames}
          width={isMobile ? containerWidth : containerHeight}
          height={isMobile ? containerWidth : containerHeight}
        />
        <CircularCarousel />
      </div>
    </div>
  );
};

export default Access;
