import React, { useState } from "react";
import "./wallet.css"; // Custom CSS for styling
import magic_ico from "../../assets/images/profile/magic.png";
import web3_ico from "../../assets/images/profile/web3.png";
import amazon_ico from "../../assets/images/profile/amazon.png";

const WalletComp = () => {
  const [selectedPayment, setSelectedPayment] = useState("Revolut");

  const handlePaymentChange = (payment) => {
    setSelectedPayment(payment);
  };

  return (
    <div className="wallet-section container mt-5">
      {/* Auth Method Buttons */}
      <div className="auth-buttons">
        <button className="auth-btn magic-link active">
          <img className="img-fluid" src={magic_ico} alt="magic wallet" />
          Magic link
        </button>
        <button className="auth-btn web3auth">
          <img className="img-fluid" src={web3_ico} alt="Web3 wallet" />
          Web3Auth
        </button>
      </div>

      {/* Add New Wallet Button */}
      <div className="add-wallet-btn text-center">
        <button className="btn btn-outline-primary">Add new wallet</button>
      </div>

      {/* Recent Transactions */}
      <div className="transactions-card mt-4">
        <div className="transaction">
          <div className="transaction-info">
            <img src={amazon_ico} alt="Amazon" className="transaction-icon" />
            <div>
              <p>Amazon</p>
              <small>Today, 20:07</small>
            </div>
          </div>
          <div className="transaction-amount">-11,99$</div>
        </div>

        <div className="transaction">
          <div className="transaction-info">
            <img src={amazon_ico} alt="Amazon" className="transaction-icon" />
            <div>
              <p>Amazon</p>
              <small>Today, 20:07</small>
            </div>
          </div>
          <div className="transaction-amount">-91,99$</div>
        </div>

        <p className="text-center show-all-link">Show All</p>
      </div>

      {/* Payment Methods */}
      <div className="payment-methods mt-4">
        <div className="payment-method">
          <label className="form-check-label">
            <input
              type="radio"
              name="payment"
              value="Revolut"
              checked={selectedPayment === "Revolut"}
              onChange={() => handlePaymentChange("Revolut")}
              className="form-check-input"
            />
            <span>Revolut</span>{" "}
            <small className="text-muted">debit card</small>
            <span className="payment-number">**** **** **** 9812</span>
          </label>
          <button className="edit-btn">
            <i className="far fa-edit"></i>
          </button>
        </div>

        <div className="payment-method">
          <label className="form-check-label">
            <input
              type="radio"
              name="payment"
              value="BBVA"
              checked={selectedPayment === "BBVA"}
              onChange={() => handlePaymentChange("BBVA")}
              className="form-check-input"
            />
            <span>BBVA</span> <small className="text-muted">bank account</small>
            <span className="payment-number">**** **** **** 3321</span>
          </label>
          <button className="edit-btn">
            <i className="far fa-edit"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default WalletComp;
