import React, { useEffect } from "react";
import "./realworld.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);

const RealWorld = () => {
  useEffect(() => {
    // Split the texts
    const headingText = new SplitType(".realworld h3", { types: "words" });
    const titleText = new SplitType(".realworld h1", { types: "words" });
    const paragraphText = new SplitType(".realworld p", { types: "words" });

    // Create a timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".realworld h1",
        start: "top 60%",
        toggleActions: "play none none reverse",
      },
    });

    // Add animations to the timeline
    tl.fromTo(
      headingText.words,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        ease: "power3.out",
        stagger: 0.1,
      }
    )
      .fromTo(
        titleText.words,
        { opacity: 0, y: 30, scale: 0.9 },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 1.8,
          ease: "power3.out",
          stagger: 0.1,
        },
        "-=1" // Start 1 second before the previous animation ends
      )
      .fromTo(
        paragraphText.words,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 1.6,
          ease: "power3.out",
          stagger: 0.05,
        },
        "-=1" // Start 1 second before the previous animation ends
      );

    // Clean up function
    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div className="realworld">
      <div className="container-fluid">
        <h3 className="text-white">Be well. Do good.</h3>
        <h1 className="section-title">REXLA WORLD FOUNDATION</h1>
        <div className="container">
          <p className="section-detail">
            Where our commitment to creating positive change on a global scale
            is unwavering.
            <br />
            As the philanthropic arm of Rexla Global, RWF is dedicated to
            addressing pressing global challenges such as poverty, agricultural
            issues, deforestation, climate change, and more.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RealWorld;
