const VisionSection = () => {
  return (
    <div className="section" id="vision">
      <div className="container-fluid">
        <h1 className="token-title vision-1">RXG TOKEN</h1>
        <div className="col-lg-4 text-start px-5">
          <div className="vision-2">
            <h5>OUR VISION</h5>

            {/* <h4>Transforming Possibilities intro Reality</h4> */}
            <p>
              At the core of RXG lies a bold vision to democratise access to
              financial services, emboldening the individual to seize new
              opportunities and contribute to a better future. Regardless of
              your background or location, you can harness the power of
              blockchain technology to achieve financial freedom and prosperity.
              We have painstakingly designed RXG to be the vehicle for this
              revolution.
            </p>
          </div>
          <div className="vision-3 mt-4">
            {/* <h5>OUR MISSION</h5>
            <h4>Empowering Through Technology</h4>
            <p>
              Our mission at RXG is clear: to empower individuals with the tools
              and resources they need to thrive in the digital age. Through our
              comprehensive suite of products and services, we strive to make
              digital finance accessible, secure, and user-friendly for all. RXG
              is the thread that ties them all together. One method of value
              exchange to power a diverse set of initiatives suited for the
              decentralised world.
            </p>{" "}
            */}
          </div>
          <div className="vision-4">
            <button className="btn btn-primary mt-5">Learn More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionSection;
