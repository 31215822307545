import rexlaAxios from "./api";
import showSwal from "../utils/showSwal";

export const userSignup = async (userMetadata) => {
  try {
    const response = await rexlaAxios.post("userSignup", {
      address: userMetadata.publicAddress,
      email: userMetadata.email,
    });
    if (response.data && response.data.success === true) {
      return response.data;
    }
    return;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};
