import "./hero.css";
const HeroSection = () => {
  return (
    <div className="section" id="hero">
      <div className="position-absolute block-top w-100">
        <h1>Revolutionising the Future of Digital Finance</h1>
        <p className="mt-3">
          Rexla Global’s utility token that serves to drive innovation, foster
          inclusivity, and empower individuals worldwide to participate in the
          decentralized economy. <br />
          Exchange value and support causes like never before.
        </p>
      </div>

      <div className="block-bottom position-absolute w-100">
        <button className="btn btn-primary">Learn More</button>
      </div>
    </div>
  );
};

export default HeroSection;
