import React, { useEffect, useRef } from "react";
import "./projects.css";
import diagram from "../../../assets/images/landing/diagram.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Projects = () => {
  const projectsRef = useRef(null);

  useEffect(() => {
    const projectsElement = projectsRef.current;

    // Create a GSAP timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: projectsElement,
        start: "top 60%",
        toggleActions: "play none none reverse",
      },
    });

    // Add animations to the timeline
    tl.fromTo(
      projectsElement.querySelector(".container"),
      { opacity: 0, y: 30 },
      { opacity: 1, y: 0, duration: 0.5, ease: "power3.out" }
    )
      .fromTo(
        projectsElement.querySelectorAll(".nav-link"),
        { opacity: 0, y: 30 },
        {
          opacity: 1,
          y: 0,
          duration: 0.6,
          stagger: 0.2,
          ease: "power2.out",
        },
        "-=0.4"
      )
      .fromTo(
        projectsElement.querySelector(".tab-content"),
        { opacity: 0, scale: 0.85 },
        {
          opacity: 1,
          scale: 1,
          duration: 1.5,
          ease: "power3.out",
        },
        "-=0.2"
      );

    // Add hover animation for nav links
    projectsElement.querySelectorAll(".nav-link").forEach((link) => {
      link.addEventListener("mouseenter", () => {
        gsap.to(link, { scale: 1.05, duration: 0.3, ease: "power2.out" });
      });
      link.addEventListener("mouseleave", () => {
        gsap.to(link, { scale: 1, duration: 0.3, ease: "power2.out" });
      });
    });

    // Animate tab change
    const tabEls = projectsElement.querySelectorAll(".tab-pane");
    tabEls.forEach((tab) => {
      tab.addEventListener("show.bs.tab", function (event) {
        gsap.fromTo(
          event.target,
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: 0.5, ease: "power2.out" }
        );
      });
    });

    // Clean up function
    return () => {
      tl.kill();
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div className="projects" ref={projectsRef}>
      <nav>
        <div className="container">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className="nav-link active"
              id="nav-guide-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-guide"
              type="button"
              role="tab"
              aria-controls="nav-guide"
              aria-selected="true"
            >
              Rexla Guide
            </button>
            <button
              className="nav-link"
              id="nav-wallet-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-wallet"
              type="button"
              role="tab"
              aria-controls="nav-wallet"
              aria-selected="false"
            >
              Rexla Wallet
            </button>
            <button
              className="nav-link"
              id="nav-exchange-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-exchange"
              type="button"
              role="tab"
              aria-controls="nav-exchange"
              aria-selected="false"
            >
              Rexla Exchange
            </button>
            <button
              className="nav-link"
              id="nav-protect-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-protect"
              type="button"
              role="tab"
              aria-controls="nav-protect"
              aria-selected="false"
            >
              Rexla Protect
            </button>
          </div>
        </div>
      </nav>
      <div className="container mt-4">
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-guide"
            role="tabpanel"
            aria-labelledby="nav-guide-tab"
          >
            <img className="w-100" src={diagram} alt="diagram" />
          </div>
          <div
            className="tab-pane fade"
            id="nav-wallet"
            role="tabpanel"
            aria-labelledby="nav-wallet-tab"
          >
            <img className="w-100" src={diagram} alt="diagram" />
          </div>
          <div
            className="tab-pane fade"
            id="nav-exchange"
            role="tabpanel"
            aria-labelledby="nav-exchange-tab"
          >
            <img className="w-100" src={diagram} alt="diagram" />
          </div>
          <div
            className="tab-pane fade"
            id="nav-protect"
            role="tabpanel"
            aria-labelledby="nav-protect-tab"
          >
            <img className="w-100" src={diagram} alt="diagram" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
