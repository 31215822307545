import { Navbar } from "../../components/navbar";
import JoinSection from "../../sections/Home/join";
import "./purchase.css";

const Purchase = () => {
  return (
    <>
      <Navbar />
      <div className="content">
        <div id="ico">
          <div className="bg-fix" />
          <JoinSection />
        </div>
      </div>
    </>
  );
};

export default Purchase;
