import React, { useState, useEffect, useCallback, useRef } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import HomeMobile from "./pages/HomeMobile";
import Purchase from "./pages/Purchase";
import Onboarding from "./pages/Onboarding";
import Landing from "./pages/Landing";
import Hub from "./pages/Hub";
import Profile from "./pages/Profile";
import LoadingScreen from "./components/loading";
import { gsap } from "gsap";

const getImagePath = (isMobile, index) => {
  const folder = isMobile ? "mobile" : "hero";
  return `./assets/images/${folder}/${index.toString().padStart(4, "0")}.png`;
};

const getImageCount = (isMobile) => (isMobile ? 581 : 1012);

function AppContent() {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < window.innerHeight
  );
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const imagesRef = useRef([]);
  const location = useLocation();

  // Function to preload images (only for home route)
  const preloadImages = useCallback(
    (callback) => {
      const imageCount = getImageCount(isMobile);
      let loadedImages = 0;

      for (let i = 0; i < imageCount; i++) {
        const img = new Image();
        img.src = getImagePath(isMobile, i);
        img.onload = () => {
          loadedImages++;
          setLoadedImageCount(loadedImages);
          if (loadedImages === imageCount) {
            callback();
          }
        };
        imagesRef.current.push(img);
      }
    },
    [isMobile]
  );

  useEffect(() => {
    const handlePageLoad = () => {
      gsap.to(".loading-screen", {
        duration: 1,
        opacity: 0,
        onComplete: () => setLoading(false),
      });
    };

    // Show the loader on route change
    setLoading(true);

    // Check if it's the home route (requires image preloading)
    if (location.pathname === "/") {
      preloadImages(() => {
        handlePageLoad();
      });
    } else {
      // For other routes, listen for the DOMContentLoaded event instead
      if (document.readyState === "complete") {
        handlePageLoad(); // If the document is already loaded
      } else {
        window.addEventListener("load", handlePageLoad);
      }

      return () => window.removeEventListener("load", handlePageLoad); // Clean up
    }
  }, [location.pathname, preloadImages]);

  useEffect(() => {
    // Handle mobile/desktop switching based on window size
    const handleResize = () => {
      const newIsMobile = window.innerWidth < window.innerHeight;
      if (newIsMobile !== isMobile && location.pathname === "/") {
        setIsMobile(newIsMobile);
        setLoading(true);
        setLoadedImageCount(0);
        imagesRef.current = [];
        preloadImages(() => {
          gsap.to(".loading-screen", {
            duration: 1,
            opacity: 0,
            onComplete: () => setLoading(false),
          });
        });
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile, preloadImages, location.pathname]);

  // Show loading screen until the page is fully ready
  if (loading) {
    return (
      <LoadingScreen
        progress={
          location.pathname === "/"
            ? loadedImageCount / getImageCount(isMobile)
            : null
        }
      />
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          isMobile ? (
            <HomeMobile preloadedImages={imagesRef.current} />
          ) : (
            <Home preloadedImages={imagesRef.current} />
          )
        }
      />
      <Route path="/ico" element={<Purchase />} />
      <Route
        path="/onboarding"
        element={<Navigate to="/onboarding/access" />}
      />
      <Route path="/onboarding/:step" element={<Onboarding />} />
      <Route path="/landing" element={<Landing />} />
      <Route path="/hub" element={<Hub />} />
      <Route path="/user" element={<Profile />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <AppContent />
      </div>
    </Router>
  );
}

export default App;
