import React, { useEffect } from "react";
import "./timeline.css";
import vision_ico from "../../../assets/images/landing/vision.png";
import approach_ico from "../../../assets/images/landing/approach.png";
import blockchain_ico from "../../../assets/images/landing/blockchain.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);

const Timeline = () => {
  useEffect(() => {
    // Split text for cards and timeline steps
    const changeTitle = new SplitType(".time-line .change-title", {
      types: "words",
    });
    const movementText = new SplitType(".time-line .section-title", {
      types: "words",
    });
    const stepHeadings = new SplitType(".time-line .step-content h4", {
      types: "words",
    });

    // Create main timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".time-line",
        start: "top 60%",
        toggleActions: "play none none reverse",
      },
    });

    const mainTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".time-line .timeline-block",
        start: "top 70%",
        toggleActions: "play none none reverse",
      },
    });

    // Add animations to the timeline
    tl.fromTo(
      ".time-line .card",
      { opacity: 0, scale: 0.9, y: 30 },
      {
        opacity: 1,
        scale: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        stagger: 0.3,
      }
    );

    mainTl
      .fromTo(
        changeTitle.words,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          delay: 0.5,
          ease: "power3.out",
          stagger: 0.1,
        }
      )
      .fromTo(
        movementText.words,
        { opacity: 0, y: 50, scale: 0.8 },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 1.8,
          stagger: 0.1,
          ease: "power3.out",
        },
        "-=1" // Start 1 second before the previous animation ends
      )

      .fromTo(
        ".stepper",
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          zIndex: 2,
          ease: "power3.out",
          stagger: 0.5,
        },
        "-=1" // Start 0.5 seconds before the previous animation ends
      )
      /* .fromTo(
        stepHeadings.words,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
          stagger: 0.05,
        },
        "-=0.5" // Start 0.5 seconds before the previous animation ends
      ) */
      .fromTo(
        ".step",
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          zIndex: 2,
          ease: "power3.out",
          stagger: 0.5,
        },
        "-=1" // Start 0.5 seconds before the previous animation ends
      )

      .fromTo(
        ".step-number",
        { borderWidth: 0, borderStyle: "none" },
        {
          borderWidth: 2,
          borderColor: "#1277db",
          duration: 1.5,
          stagger: 0.3,
        },
        "-=2" // Start 2 seconds before the previous animation ends
      );

    // Clean up function
    return () => {
      tl.kill();
      mainTl.kill();
    };
  }, []);

  return (
    <div className="time-line">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 mb-4">
            <div className="card h-100 shadow-sm text-start">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="card-title text-primary">The vision</h4>
                  <img className="img-fluid" src={vision_ico} alt="vision" />
                </div>
                <p className="card-text">
                  At RWF, we are driven by a vision of a better world, where
                  every individual has access to the resources and opportunities
                  they need to thrive. We believe in taking proactive and
                  innovative approaches to tackle the root causes of these
                  challenges, rather than simply addressing their symptoms.
                  <br />
                  <br />
                  By focusing on sustainable solutions, we aim to uplift
                  communities and safeguard our planet for future generations.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mb-4">
            <div className="card h-100 shadow-sm text-start">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="card-title text-primary">The approach</h4>
                  <img
                    className="img-fluid"
                    src={approach_ico}
                    alt="approach"
                  />
                </div>

                <p className="card-text">
                  Beyond individual learning, Rexla Guild is dedicated to
                  empowering communities through education at RWF. We recognize
                  the importance of taking a fresh and adaptive approach to
                  solving global issues.
                  <br />
                  <br />
                  We understand that there is no one-size-fits-all solution and
                  that each challenge requires a tailored strategy. Whether it’s
                  empowering vulnerable communities, preserving endangered
                  ecosystems, or advocating for social justice.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mb-4">
            <div className="card h-100 shadow-sm text-start">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="card-title text-primary">The blockchain</h4>
                  <img
                    className="img-fluid"
                    src={blockchain_ico}
                    alt="blockchain"
                  />
                </div>

                <p className="card-text">
                  Central to our mission is the use of blockchain technology to
                  raise essential funding and drive impactful change. By
                  leveraging the transparency, security, and efficiency of
                  blockchain, we can mobilize resources more effectively and
                  ensure that every contribution makes a meaningful difference
                  in the lives of those we seek to help.
                  <br />
                  <br />
                  Through blockchain, we have the opportunity to revolutionize
                  philanthropy and create lasting impact.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="timeline-block">
        <h2 className="change-title">The change is now</h2>
        <h1 className="section-title">JOIN THE MOVEMENT</h1>
        <div className="container">
          <ul className="stepper">
            {/* Step 1 */}
            <li className="step done">
              <div className="step-number">1</div>
              <div className="step-content">
                <h4>PHASE 1</h4>
                <p>
                  <i className="fa fa-check text-primary me-2"></i>{" "}
                  Establishment of Rexla Global
                </p>
                <p>
                  <i className="fa fa-check text-primary me-2"></i> Development
                  of RXG Token
                </p>
              </div>
            </li>

            {/* Step 2 */}
            <li className="step done">
              <div className="step-number">2</div>
              <div className="step-content">
                <h4>PHASE 2</h4>
                <p>
                  <i className="fa fa-check text-primary me-2"></i> Launch of
                  Rexla Wallet
                </p>
                <p>
                  <i className="fa fa-check text-primary me-2"></i> Rexla
                  Exchange Development
                </p>
                <p>
                  <i className="fa fa-check text-primary me-2"></i> Integration
                  of Rexla Protect
                </p>
                <p>
                  <i className="fa fa-check text-primary me-2"></i>Strategic
                  Partnership
                </p>
              </div>
            </li>

            {/* Step 3 */}
            <li className="step disabled">
              <div className="step-number">3</div>
              <div className="step-content">
                <h4>PHASE 3</h4>
                <p>
                  <i className="fa fa-check text-primary me-2"></i>Community
                  Building
                </p>
                <p>
                  <i className="fas fa-spinner me-1" /> Global Expansion
                </p>
                <p>
                  <i className="fas fa-spinner me-1" /> Enhanced Features
                </p>
              </div>
            </li>

            {/* {/* Step 4 */}
            {/* <li className="step disabled">
              <div className="step-number">4</div>
              <div className="step-content">
                <h4>Lorem ipsum dolor sit amet</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </li> */}

            {/* Step 5 */}
            {/* <li className="step disabled">
              <div className="step-number">5</div>
              <div className="step-content">
                <h4>Lorem ipsum dolor sit amet</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Timeline;
