import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "./join.css";
// import CustomConnectButton from "../../../components/CustomConnectButton";
import Countdown from "../../../components/countdown";
// import { useAccount, useBalance } from "wagmi";
import { buyTokensMagic } from "../../../web3/buyToken";
import showSwal from "../../../utils/showSwal";
import WalletConnectButton from "../../../components/WalletConnectButton";
import { useMagic } from "../../../hooks/MagicProvider";

const JoinSection = () => {
  // const { address, isConnected } = useAccount();
  const { isLoggedIn, provider, balance } = useMagic();
  const [ethAmount, setEthAmount] = useState(0);
  const [rxgAmount, setRxgAmount] = useState(0);
  const [isInsufficient, setIsInsufficient] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    let ethAmount = process.env.REACT_APP_PRICE * rxgAmount;
    setEthAmount(ethAmount);

    setIsInsufficient(ethAmount > balance?.formatted);
  }, [rxgAmount, balance]);

  const handleBuyToken = async () => {
    if (!isLoggedIn) {
      showSwal("Please connect your wallet!", "warning");
    } else if (ethAmount > 0) {
      setLoading(true);
      await buyTokensMagic(ethAmount, provider);
      setLoading(false);
    } else {
      showSwal("Please input the RXG amount!", "warning");
    }
  };

  return (
    <div className="section" id="join">
      <div className="container-fluid">
        <div className="join-top d-flex flex-column align-items-center">
          <h1 className="join-title">Join RXG Token</h1>
          <p>
            We are currently conducting Seed and Private Sales for investment
            rounds, with the Public Presale going live this summer.
          </p>
          <p>
            If you would like to stay updated on the launch and distribution of
            RXG, sign up HERE {/* [link to /sign-up] */} for updates.
          </p>
          {location.pathname == "/ico" && (
            <div className="mt-2">
              <Countdown targetDate={1732424341000} />
            </div>
          )}
        </div>

        <div className="btn-group">
          {/* <Countdown targetDate={1732424341000} /> */}
          <div className="d-flex justify-content-center gap-3 w-100 mt-5">
            {/* <button className="btn btn-outline-primary">
              CONNECT YOUR WALLET
            </button> */}
            {/* <CustomConnectButton className="btn btn-outline-primary" /> */}
            <WalletConnectButton className="btn btn-outline-primary" />
            <button
              className="btn btn-primary flex-1"
              onClick={() => handleBuyToken()}
              // disabled={isInsufficient || !isConnected || loading}
              disabled={!isLoggedIn || loading || isInsufficient}
            >
              BUY RXG TOKEN
            </button>
          </div>

          <div className="mt-4">
            <h2>1 RXG = {process.env.REACT_APP_PRICE} ETH</h2>
          </div>

          <div className="rxg-form d-flex algin-items-center justify-content-center gap-4 mt-4">
            <div className="text-start">
              <label className="text-start">RXG you receive</label>
              <input
                className="form-control"
                value={rxgAmount}
                onChange={(e) => setRxgAmount(e.target.value)}
                type="number"
              />
            </div>
            <div className="text-start">
              <label className="text-start">Eth you pay</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={ethAmount}
                  onChange={(e) => setEthAmount(e.target.value)}
                  readOnly
                />

                {/* <div className="input-group-append">
                  <span className="input-group-text">$</span>
                </div> */}
              </div>
              <p className="notify">
                {isInsufficient && "Insufficient funds!"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinSection;
