import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { sepolia } from "wagmi/chains";
import { http } from "wagmi";

export const config = getDefaultConfig({
  appName: "Rexla App",
  projectId: process.env.REACT_APP_WALLET_PROJECT_ID,
  chains: [sepolia /*  polygon, optimism, arbitrum, base */],
  transports: {
    [sepolia.id]: http(
      `https://eth-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_KEY}`
    ),
  },
  // ssr: true, // If your dApp uses server side rendering (SSR)
});

export default config;
