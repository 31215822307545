import { useEffect } from "react";
import "./hero.css";
import { LandingNavbar } from "../../../components/LandingNavbar";
import { gsap, Power3 } from "gsap";

const Hero = () => {
  useEffect(() => {
    const tl = gsap.timeline();

    // Hero background parallax effect
    gsap.fromTo(
      ".hero",
      { backgroundPosition: "center bottom" },
      { backgroundPosition: "center 20%", duration: 3, ease: Power3.easeOut }
    );

    // Staggered text animation for a professional feel
    tl.fromTo(
      ".hero .navbar",
      { y: -50 },
      {
        y: 0,
        scale: 1,
        duration: 1,
        ease: Power3.easeOut,
        delay: 0.5,
      }
    )
      .fromTo(
        ".hero .hero-section h3",
        { opacity: 0, y: 30, scale: 0.9, filter: "blur(4px)" },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          filter: "blur(0px)",
          duration: 1.5,
          ease: Power3.easeOut,
          delay: 0.5,
        }
      )
      .fromTo(
        ".hero-section h1",
        { opacity: 0, y: 30, scale: 0.9, filter: "blur(4px)" },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          filter: "blur(0px)",
          duration: 1.5,
          ease: Power3.easeOut,
        },
        "-=1" // overlap the animation with the previous one for smoother transitions
      )
      .fromTo(
        ".join-btn",
        { opacity: 0, scale: 0.8 },
        { opacity: 1, scale: 1, duration: 1.5, ease: Power3.easeOut },
        "-=0.5"
      );

    // Add subtle floating effect for continuous animation
    /* gsap.to(".hero", {
      backgroundPosition: "center",
      repeat: -1,
      yoyo: true,
      ease: Power3.easeInOut,
      duration: 4,
    }); */
    // Clean up function
    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div className="hero">
      <LandingNavbar />
      <div className="hero-section container-fluid">
        <h3 className="text-white">
          Blockchain, now for the other 96% of the planet
        </h3>
        <h1>
          YOUR LAUNCHPAD TO THE NEW
          <br />
          DIGITAL ECONOMY
        </h1>
      </div>
    </div>
  );
};

export default Hero;
