import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import card_icon1 from "../../../assets/images/landing/credibility.png";
import card_icon2 from "../../../assets/images/landing/compliance.png";
import card_icon3 from "../../../assets/images/landing/security.png";
import logo_bottom from "../../../assets/images/landing/logo-placeholder.png";
import livello from "../../../assets/images/landing/livello.png";
import Countdown from "../../../components/countdown";
import "./foundation.css";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const Foundation = () => {
  useEffect(() => {
    // Create main timeline
    const mainTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".foundation",
        start: "top 60%",
        toggleActions: "play none none reverse",
      },
    });

    // Split texts
    const splitText1 = new SplitType(".foundation h3.text-white", {
      types: "words, chars",
    });
    const splitText2 = new SplitType(".foundation-title", {
      types: "words, chars",
    });
    const splitText3 = new SplitType(".foundation .description", {
      types: "words, chars",
    });
    const splitText4 = new SplitType(".foundation h2.text-white", {
      types: "words, chars",
    });
    const splitSecurityText = new SplitType(".foundation .security-text", {
      types: "words, chars",
    });

    // Add animations to the main timeline
    mainTl
      .fromTo(
        splitText1.chars,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1.5, stagger: 0.05, ease: "power3.out" }
      )
      .fromTo(
        splitText3.words,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.2, stagger: 0.05, ease: "power3.out" },
        "-=1"
      )
      .fromTo(
        ".foundation .countdown",
        { opacity: 0, y: 50, scale: 0.8 },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 1,
          stagger: 0.05,
          ease: "power3.out",
        },
        "-=0.5"
      )

      .fromTo(
        ".foundation .ico-btn",
        { opacity: 0, y: -50, scale: 0.8 },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 0.5,
          stagger: 0.05,
          ease: "power3.out",
        },
        "-=3"
      );

    const titleTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".foundation .foundation-title",
        start: "top 90%",
        toggleActions: "play none none reverse",
      },
    });

    titleTl.fromTo(
      splitText2.chars,
      { opacity: 0, scaleX: 0, visibility: "hidden" },
      {
        opacity: 1,
        scaleX: 1,
        visibility: "visible",
        duration: 0.1,
        stagger: 0.1,
        ease: "power3.out",
      }
    );

    // Create feature cards timeline
    const featureCardsTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".foundation .feature-card",
        start: "top 80%",
        toggleActions: "play none none reverse",
      },
    });

    featureCardsTl
      .fromTo(
        ".foundation .feature-card",
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1.2, ease: "power3.out", stagger: 0.2 }
      )
      .fromTo(
        ".foundation .card-title",
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 1.5, stagger: 0.05, ease: "power3.out" },
        "-=1"
      );

    // Create security section timeline
    const securityTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".foundation h2.text-white",
        start: "top 80%",
        toggleActions: "play none none reverse",
      },
    });

    securityTl
      .fromTo(
        splitText4.chars,
        { opacity: 0 },
        { opacity: 1, y: 0, duration: 1.5, stagger: 0.05, ease: "power3.out" }
      )
      .fromTo(
        splitSecurityText.words,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 0.09, stagger: 0.05, ease: "power3.out" },
        "-=2"
      )
      .fromTo(
        ".foundation .dark-card",
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 1.2, ease: "power3.out" },
        "-=3"
      )
      .fromTo(
        ".foundation .dark-card li",
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 1.2, stagger: 0.2, ease: "power3.out" },
        "-=3"
      )
      .fromTo(
        ".foundation .bottom-img",
        { rotateX: 90, opacity: 0 },
        { rotateX: 0, opacity: 1, duration: 1.5, ease: "power2.out" },
        "-=0.5"
      );

    // Button hover animation
    gsap.to(".foundation .ico-btn", {
      scale: 1.05,
      duration: 0.3,
      repeat: -1,
      yoyo: true,
      ease: "power2.inOut",
    });

    // Clean up function
    return () => {
      mainTl.kill();
      featureCardsTl.kill();
      securityTl.kill();
    };
  }, []);

  return (
    <div className="foundation">
      <Link to="/ico" target="_blank" className="btn btn-primary ico-btn">
        ICO BUTTON
      </Link>
      <div className="container">
        <h3 className="text-white">WE'RE ALMOST THERE</h3>
        <p className="description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        <div className="countdown">
          <Countdown targetDate={1732424341000} />
        </div>
      </div>

      <div className="container mt-5">
        <h1 className="foundation-title text-start">
          A FOUNDATION OF <span>TRUST.</span>
        </h1>
      </div>

      <div className="livello-section">
        <img src={livello} alt="livello" className="w-100 livello-img" />
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-4">
              <div className="card feature-card text-center">
                <div className="card-body">
                  <div className="feature-icon mb-4">
                    <img
                      src={card_icon1}
                      alt="Credibility Icon"
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="card-title">Credibility</h5>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card feature-card text-center">
                <div className="card-body">
                  <div className="feature-icon mb-4">
                    <img
                      src={card_icon2}
                      alt="Compliance Icon"
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="card-title">Compliance</h5>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card feature-card text-center">
                <div className="card-body">
                  <div className="feature-icon mb-4">
                    <img
                      src={card_icon3}
                      alt="Security Icon"
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="card-title">Security</h5>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="text-white">Enterprise-grade security & compliance</h2>
        <p className="security-text">
          Rexla was built from the ground up for global compliance and security
          in all markets.
          <br />
          Our approach is centered the highest standards of transparency,
          security, and ethical practice.
          <br />
          We work directly with regulatory bodies, sharing insights and
          expertise to help shape a framework that is both robust and adaptable.
          <br />
          By working together with regulators, we aim to cultivate a compliant
          environment that fosters innovation while ensuring the protection and
          trust of users.
          <br />
          Our goal is to pave the way for a regulatory model that is
          forward-thinking, inclusive, and sustainable.
        </p>

        <div className="card dark-card shadow-lg text-white">
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fa fa-check text-primary me-2"></i>
              Non-custodial wallet: You have sole control over your keys and
              assets
            </li>
            <li className="mb-3">
              <i className="fa fa-check text-primary me-2"></i>
              Single sign-on across entire product suite
            </li>
            <li className="mb-3">
              <i className="fa fa-check text-primary me-2"></i>
              Easy authentication with your preferred authentication method
            </li>
            <li className="mb-3">
              <i className="fa fa-check text-primary me-2"></i>
              No need to hold verification codes or authentication tools
            </li>
            <li>
              <i className="fa fa-check text-primary me-2"></i>
              SOC 2 Type 2, GDPR, CCPA compliant
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom-block px-4">
        <img
          className="img-fluid bottom-img"
          src={logo_bottom}
          alt="collaborators"
        />
      </div>
      <div className="container form-block mt-5 pb-4">
        <div
          className="elfsight-app-96784cab-c053-4158-a46a-69065634238e"
          data-elfsight-app-lazy
        ></div>
      </div>
    </div>
  );
};

export default Foundation;
