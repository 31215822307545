import React, { useState } from "react";

const items = [
  { name: "Rexla", image: "../assets/images/access/Rex.png" },
  { name: "USocial", image: "../assets/images/access/uSocial@4x.png" },
  { name: "H2X", image: "../assets/images/access/h2x@4x.png" },
  { name: "RProtect", image: "../assets/images/access/RProtect@4x.png" },
  { name: "BWF", image: "../assets/images/access/BWF@4x.png" },
  { name: "RExchange", image: "../assets/images/access/REXCHANGE.png" },
  { name: "RXG", image: "../assets/images/access/RXG.png" },
  { name: "Wallet", image: "../assets/images/access/RWalletv1@3x.png" },
];

const CircularCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const rotateLeft = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const rotateRight = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const getItemStyle = (index) => {
    const positions = [
      { bottom: "34%", left: "50%" },
      { bottom: "35%", left: "70%" },
      { bottom: "45%", left: "85%" },
      { bottom: "60%", left: "70%" },
      { bottom: "63%", left: "50%" },
      { bottom: "60%", left: "30%" },
      { bottom: "45%", left: "15%" },
      { bottom: "35%", left: "30%" },
    ];

    const rotatedIndex = (index - currentIndex + items.length) % items.length;
    const position = positions[rotatedIndex];

    return {
      position: "absolute",
      transition: "all 0.5s ease-in-out",
      transform:
        rotatedIndex === 0
          ? "translateX(-50%) scale(1.4)"
          : "translateX(-50%) scale(1)",
      zIndex: rotatedIndex === 0 ? 10 : 1,
      ...position,
    };
  };

  return (
    <div className="carousel-container">
      {items.map((item, index) => (
        <div className="slider-item" key={index} style={getItemStyle(index)}>
          <img
            src={item.image}
            alt={item.name}
            style={{ width: "80px", height: "80px", objectFit: "contain" }}
          />
        </div>
      ))}
      <div className="button-block">
        <button
          className="btn btn-prmary rounded-pill px-4 text-white"
          onClick={rotateLeft}
        >
          <i className="me-2 fa fa-chevron-left" />
        </button>
        <span className="text-white">{items[currentIndex].name}</span>
        <button
          className="btn btn-prmary rounded-pill px-4 text-white"
          onClick={rotateRight}
        >
          <i className="ms-2 fa fa-chevron-right" />
        </button>
      </div>
    </div>
  );
};

export default CircularCarousel;
