// import discount_ico from "../../../assets/images/discount.png";
// import reward_ico from "../../../assets/images/reward.png";
// import gover_ico from "../../../assets/images/governance.png";
import "./utility.css";

const UtilitySection = () => {
  return (
    <div
      className="section text-start d-flex align-items-center text-center flex-column"
      id="utility"
    >
      <div className="benefit-block position-relative">
        <div className="utility-1 container-fluid">
          <div className="text-start d-inline-block">
            <h5>TOKEN</h5>
            <h1>Benefits</h1>
          </div>
          <h6>RXG Token: The Catalyst for Change</h6>
          <p>
            RXG is the cornerstone of our ecosystem, facilitating seamless
            transactions, powering innovative solutions, and unlocking new
            possibilities for our community. As a utility token, RXG plays a
            pivotal role in enabling various functions within our platform:
            trading, investment, governance, and more.
          </p>
        </div>
        {/* <div className="d-flex align-items-center gap-4 mb-3 mt-4">
                <img src={discount_ico} alt="discount" />
                <div>
                  <h6>Discounts</h6>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 mb-3">
                <img src={reward_ico} alt="reward" />
                <div>
                  <h6>Staking Rewards</h6>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4">
                <img src={gover_ico} alt="governance" />
                <div>
                  <h6>Governance Participation</h6>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
              </div> */}
      </div>
      <div className="utility-block position-relative d-flex flex-column align-items-center justify-content-end">
        <div className="utility-1 container-fluid">
          <div className="text-start d-inline-block">
            <h5>TOKEN</h5>
            <h1>Utility</h1>
          </div>
          <p>
            RXG is a fixed supply utility token that incentivises active users,
            early adopters and investors. We encourage you to take a deeper dive
            into our release schedule, projections and rewards structure
            outlined in our Tokenomics Paper below.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UtilitySection;
