import axios from "axios";

const rexlaAxios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL, //YOUR_API_URL HERE
  /* withCredentials: true, */
  headers: {
    "Content-Type": "application/json",
  },
});

export default rexlaAxios;
