import "./funds.css";
import logo from "../../../assets/images/onboarding/logo-white.png";
import { useState } from "react";

const Funds = ({ onNext, isMobile }) => {
  const [balance, setBalance] = useState(0);
  const onAddFund = () => {
    console.log("add fund");
  };

  return (
    <div className="funds w-100">
      <div className="top-block flex-grow-1">
        <div className="row">
          <div className="col-2 text-start">
            <img className="section-logo" src={logo} alt="logo" />
          </div>
          <div className="col-8 section-title-block">
            {!isMobile && (
              <>
                <h2 className="section-title">Fund your Wallet</h2>
                <p>You need funds to trade</p>
              </>
            )}
          </div>
          <div className="col-2 text-end">
            <i className="fa fa-info-circle"></i>
          </div>
        </div>
        {isMobile && (
          <div className="section-title-block mt-4">
            <h2 className="section-title">Fund your Wallet</h2>
            <p>You need funds to trade</p>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-center flex-fill">
          <div className="input-block d-flex align-items-center rounded-pill">
            <div className="flex-grow-1 d-flex align-items-center px-3 ps-4 py-2">
              <span className="fs-2 fw-bold text-white me-2 text-opacity-25">
                $
              </span>
              <input
                type="text"
                value={balance}
                onChange={(e) => setBalance(e.target.value)}
                className="form-control-plaintext fs-2 ms-3 fw-bold text-white border-0"
                style={{ width: "auto", boxShadow: "none" }}
              />
            </div>
            <button
              onClick={onAddFund}
              className="btn bg-white rounded-pill px-5 py-2 me-2"
            >
              Add Fund
            </button>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 d-flex align-items-center flex-column justify-content-center px-5">
        <div className="check-box">
          <i className="fa fa-check"></i>
        </div>
        <h3>
          You have been verified from our system, it’s time to fund your wallet
          for start trading with Rexla.
        </h3>
        <a className="skip-link" onClick={onNext}>
          Skip this step for now
        </a>
      </div>
    </div>
  );
};

export default Funds;
