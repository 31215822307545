import rexlaAxios from "./api";
import showSwal from "../utils/showSwal";

export const getKycURL = async ({ address }) => {
  try {
    const response = await rexlaAxios.post("getKycURL", { address });
    if (response.data && response.data.success === true) {
      return response.data.data;
    }
    return;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};

export const getKycVerification = async ({ address, verification_id }) => {
  try {
    const response = await rexlaAxios.post("getKycVerification", {
      address,
      verification_id,
    });
    if (response.data && response.data.success === true) {
      return response.data.data;
    }
    return;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};
