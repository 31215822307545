// src/LoadingScreen.js
import React from "react";
import "./LoadingScreen.css"; // Create this CSS file for styling

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingScreen;
