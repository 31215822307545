import { OAuthExtension } from "@magic-ext/oauth";
import { ethers } from "ethers";
import { Magic as MagicBase } from "magic-sdk";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const MagicContext = createContext({
  magic: null,
  isLoggedIn: false,
  userMetadata: null,
  provider: null,
  handleConnectWallet: async () => {},
  shortAddress: "",
  isLoading: false,
  balance: null,
});

export const useMagic = () => useContext(MagicContext);

const MagicProvider = ({ children }) => {
  const [magic, setMagic] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userMetadata, setUserMetadata] = useState(null);
  const [shortAddress, setShortAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    const magic = new MagicBase(process.env.REACT_APP_MAGIC_API_KEY, {
      /* network: {
        rpcUrl: "https://rpc2.sepolia.org", // Replace with your Alchemy API key
        chainId: 11155111,
      }, */
      network: "sepolia",
      extensions: [new OAuthExtension()],
    });

    setMagic(magic);
    const provider = new ethers.providers.Web3Provider(magic.rpcProvider);
    /* await provider.send("eth_requrestAccounts", []); */
    setProvider(provider);
  }, []);

  const checkUserLoggedIn = async () => {
    if (magic) {
      setIsLoading(true);
      try {
        const isLoggedIn = await magic.user.isLoggedIn();
        setIsLoggedIn(isLoggedIn);
        if (isLoggedIn) {
          const metadata = await magic.user.getInfo();
          setUserMetadata(metadata);
          setShortAddress(shortenAddress(metadata.publicAddress));
          const balance = ethers.utils.formatEther(
            await provider.getBalance(metadata.publicAddress) // Balance is in wei
          );
          setBalance(balance);
        }
      } catch (error) {
        console.error("Error checking login status:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    checkUserLoggedIn();
  }, [magic]);

  const shortenAddress = (address) => {
    if (!address) return "";

    if (!/^0x[a-fA-F0-9]{40}$/.test(address)) {
      console.error("Invalid Ethereum address");
      return address;
    }

    const start = address.slice(0, 6);
    const end = address.slice(-4);

    return `${start}...${end}`;
  };

  const handleConnectWallet = async () => {
    if (magic) {
      setIsLoading(true);
      try {
        if (!isLoggedIn) {
          const accounts = await magic.wallet.connectWithUI();
          setIsLoggedIn(true);
          const metadata = await magic.user.getInfo();
          setUserMetadata(metadata);
          setShortAddress(shortenAddress(metadata.publicAddress));
          const balance = ethers.utils.formatEther(
            await provider.getBalance(metadata.publicAddress) // Balance is in wei
          );
          setBalance(balance);
          return accounts;
        } else {
          await magic.wallet.showUI();
        }
      } catch (error) {
        console.error("Error connecting wallet:", error);
        return null;
      } finally {
        setIsLoading(false);
      }
    } else checkUserLoggedIn();
  };

  const value = useMemo(() => {
    return {
      magic,
      isLoggedIn,
      userMetadata,
      provider,
      handleConnectWallet,
      shortAddress,
      isLoading,
      balance,
    };
  }, [
    magic,
    isLoggedIn,
    userMetadata,
    shortAddress,
    isLoading,
    provider,
    balance,
  ]);

  return (
    <MagicContext.Provider value={value}>{children}</MagicContext.Provider>
  );
};

export default MagicProvider;
