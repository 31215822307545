const HeroSection = () => {
  return (
    <div className="section" id="hero">
      <div className="hero-block">
        <h1 className="hero-title">Revolutionising the Future of Digital Finance</h1>
        <p className="mt-3 hero-text">
          Rexla Global’s utility token that serves to drive innovation, foster
          inclusivity, and empower individuals worldwide to participate in the
          decentralized economy. <br />
          Exchange value and support causes like never before.
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
