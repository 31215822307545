import "./roadmap.css";

const RoadmapSection = () => {
  return (
    <div className="section" id="roadmap">
      <div className="container">
        <div className="roadmap-title">
          <h4>RXG'S</h4>
          <h1 className="text-white roadmap-1">Roadmap</h1>
        </div>
        <ul className="timeline">
          <li className="timeline-1">
            <div className="date">2023</div>
            <div className="timeline-badge circle completed">
              <div className="circle-inner">
                <div className="circle-number">01</div>
              </div>
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4 className="timeline-title">
                  PHASE 1 <span className="completed-badge">COMPLETED</span>
                </h4>
              </div>
              <div className="timeline-body">
                <p>
                  <i className="fa fa-check" /> Establishment of Rexla Global
                </p>
                <p>
                  <i className="fa fa-check" /> Development of RXG Token
                </p>
              </div>
            </div>
          </li>
          <li className="timeline-2">
            <div className="date">2024</div>
            <div className="timeline-badge circle completed">
              <div className="circle-inner">
                <div className="circle-number">02</div>
              </div>
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4 className="timeline-title">
                  PHASE 2 <span className="completed-badge">COMPLETED</span>
                </h4>
              </div>
              <div className="timeline-body">
                <p>
                  <i className="fa fa-check" /> Launch of Rexla Wallet
                </p>
                <p>
                  <i className="fa fa-check" /> Rexla Exchange Development
                </p>
                <p>
                  <i className="fa fa-check" /> Integration of Rexla Protect
                </p>
                <p>
                  <i className="fa fa-check" /> Strategic Partnership
                </p>
              </div>
            </div>
          </li>
          <li className="disabled timeline-3">
            <div className="date">2025</div>
            <div className="timeline-badge circle upcoming">
              <div className="circle-inner">
                <div className="circle-number">03</div>
              </div>
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4 className="timeline-title">
                  PHASE 3{" "}
                  <span className="completed-badge upcoming">UPCOMING</span>
                </h4>
              </div>
              <div className="timeline-body">
                <p>
                  <i className="fa fa-check" /> Community Building
                </p>
                <p className="disabled">
                  <i className="fas fa-spinner" />
                  &nbsp; Global Expansion
                </p>
                <p className="disabled">
                  <i className="fas fa-spinner" />
                  &nbsp; Enhanced Features
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RoadmapSection;
